
	// var tpj=jQuery;                  
 //    var revapi486;
 //    tpj(document).ready(function() {
 //        if(tpj("#rev_slider_486_1").revolution == undefined){
 //            revslider_showDoubleJqueryError("#rev_slider_486_1");
 //        }else{
 //            revapi486 = tpj("#rev_slider_486_1").show().revolution({
	// 		sliderType:"standard",
 //                jsFileLocation:"plugins/revolution/js/",
 //                sliderLayout:"fullwidth",
 //                dottedOverlay:"none",
 //                delay:10000,
 //                navigation: {
 //                    keyboardNavigation:"off",
 //                    keyboard_direction: "horizontal",
 //                    mouseScrollNavigation:"off",
 //                        mouseScrollReverse:"default",
 //                    onHoverStop:"off",
 //                    touch:{
 //                        touchenabled:"on",
 //                        touchOnDesktop:"off",
 //                        swipe_threshold: 75,
 //                        swipe_min_touches: 1,
 //                        swipe_direction: "horizontal",
 //                        drag_block_vertical: false
 //                    },
 //                    arrows: {
 //                        style:"hermes",
 //                        enable:true,
 //                        hide_onmobile:true,
 //                        hide_under:600,
 //                        hide_onleave:false,
 //                        tmp:'',
 //                        left: {
 //                            h_align:"left",
 //                            v_align:"center",
 //                            h_offset:0,
 //                            v_offset:0
 //                        },
 //                        right: {
 //                            h_align:"right",
 //                            v_align:"center",
 //                            h_offset:0,
 //                            v_offset:0
 //                        }
 //                    }
                    
 //                },
 //                 responsiveLevels:[1200,1040,778,480],
 //                 visibilityLevels:[1200,1040,778,480],
 //                 gridwidth:[1200,1040,778,480],
 //                 gridheight:[1040,900,800,700],
 //                 lazyType:"none",
 //                 parallax: {
 //                    type:"mouse",
 //                    origo:"slidercenter",
 //                    speed:2000,
 //                    speedbg:0,
 //                    speedls:0,
 //                    levels:[2,3,4,5,10,7,12,16,10,50,47,48,49,50,51,55],
 //                },
 //                 shadow:0,
 //                 spinner:"off",
 //                 stopLoop:"off",
 //                 stopAfterLoops:-1,
 //                 stopAtSlide:-1,
 //                 shuffle:"off",
 //                 autoHeight:"off",
 //                 hideThumbsOnMobile:"off",
 //                 hideSliderAtLimit:0,
 //                 hideCaptionAtLimit:0,
 //                 hideAllCaptionAtLilmit:0,
 //                 debugMode:false,
 //                 fallbacks: {
 //                     simplifyAll:"off",
 //                     nextSlideOnWindowFocus:"off",
 //                     disableFocusListener:false,
 //                 }
 //             });
 //         }

 //     }); /*ready*/
(function(win,d, $){
    // console.log('wind', win)
     function slider(){
         if($("#rev_slider_486_1").revolution == undefined){
            revslider_showDoubleJqueryError("#rev_slider_486_1");
        }else{
            revapi486 = $("#rev_slider_486_1").show().revolution({
            sliderType:"standard",
                jsFileLocation:"plugins/revolution/js/",
                sliderLayout:"fullwidth",
                dottedOverlay:"none",
                delay:10000,
                navigation: {
                    keyboardNavigation:"off",
                    keyboard_direction: "horizontal",
                    mouseScrollNavigation:"off",
                        mouseScrollReverse:"default",
                    onHoverStop:"off",
                    touch:{
                        touchenabled:"on",
                        touchOnDesktop:"off",
                        swipe_threshold: 75,
                        swipe_min_touches: 1,
                        swipe_direction: "horizontal",
                        drag_block_vertical: false
                    },
                    arrows: {
                        style:"hermes",
                        enable:true,
                        hide_onmobile:true,
                        hide_under:600,
                        hide_onleave:false,
                        tmp:'',
                        left: {
                            h_align:"left",
                            v_align:"center",
                            h_offset:0,
                            v_offset:0
                        },
                        right: {
                            h_align:"right",
                            v_align:"center",
                            h_offset:0,
                            v_offset:0
                        }
                    }
                    
                },
                 responsiveLevels:[1200,1040,778,480],
                 visibilityLevels:[1200,1040,778,480],
                 gridwidth:[1200,1040,778,480],
                 gridheight:[1040,900,800,700],
                 lazyType:"none",
                 parallax: {
                    type:"mouse",
                    origo:"slidercenter",
                    speed:2000,
                    speedbg:0,
                    speedls:0,
                    levels:[2,3,4,5,10,7,12,16,10,50,47,48,49,50,51,55],
                },
                 shadow:0,
                 spinner:"off",
                 stopLoop:"off",
                 stopAfterLoops:-1,
                 stopAtSlide:-1,
                 shuffle:"off",
                 autoHeight:"off",
                 hideThumbsOnMobile:"off",
                 hideSliderAtLimit:0,
                 hideCaptionAtLimit:0,
                 hideAllCaptionAtLilmit:0,
                 debugMode:false,
                 fallbacks: {
                     simplifyAll:"off",
                     nextSlideOnWindowFocus:"off",
                     disableFocusListener:false,
                 }
             });
         }
    }
    win['slider_jadye'] = slider
}(window, document, jQuery))
   